import * as React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from "gatsby-plugin-image"
import {
  AppBar,
  Box,
  Button,
  Drawer,
  Divider,
  Hidden,
  IconButton,
  Typography,
  TextField
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';

import { NavItem, NavigationItem } from './NavigationItem';
import { Modal } from '../modal';
import { debounce } from '../../../utils/debounce';
import { submitForm, validateEmail, FORM_TYPE } from '../../../utils/forms';
import { trackGAEvent } from '../../../utils/analytics';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.text.primary,
      height: 60,
      padding: '0 30px',
      justifyContent: 'space-between',
      [theme.breakpoints.down("sm")]: {
        padding: 0,
        justifyContent: 'center'
      }
    },
    subscribeMobile: {
      color: theme.palette.primary.main,
      textTransform: 'lowercase',
      position: 'absolute',
      right: 4,
      fontWeight: 600,
      fontSize: theme.typography.pxToRem(16)
    },
    statusText: {
      marginTop: 5,
      fontSize: theme.typography.pxToRem(13)
    },
    menuIcon: {
      color: theme.palette.text.secondary,
      position: 'absolute',
      left: 0
    },
    button: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.text.primary,
      textTransform: 'lowercase',
      position: 'relative',
      transform: 'translate(0px, 60%)',
      marginLeft: 40,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: theme.palette.primary.main,
      }
    },
    formButton: {
      backgroundColor: theme.palette.primary.main,
      textTransform: 'lowercase',
      maxWidth: 100,
      alignSelf: 'flex-end',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: theme.palette.primary.main,
      }
    },
    buttonText: {
      fontWeight: 600,
      fontSize: theme.typography.pxToRem(16)
    },
    drawer: {
      width: 280,
      backgroundColor: theme.palette.text.primary,
    },
    divider: {
      backgroundColor: theme.palette.text.secondary,
    },
    formRoot: {
      display: 'flex',
      flexDirection: 'column',
      '& > *': {
         marginTop: theme.spacing(1),
         marginBottom: theme.spacing(1),
         width: '100%'
     }
   }
  }),
);

const PAGES: NavItem[] = [
  {
    name: 'Home',
    path: '/'
  },
  {
    name: 'about',
    path: '/about'
  },
  {
    name: 'blog',
    path: '/blog'
  },
  {
    name: 'add your company',
    path: '/addcompany'
  },
  {
    name: 'reach out',
    path: '/contact'
  }
];

const EMAIL_DEBOUNCE = 350;
const ERROR_KEY = 'unable to complete request!';
const SUCCESS_KEY = 'thank you for subscribing!';

export const NavBar = () => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [name, setName] = React.useState('');
  const [statusMessage, setStatusMessage] = React.useState('');
  const [emailValidationWarning, setEmailValidationWarning] = React.useState(false);
  const [nameValidationWarning, setNameValidationWarning] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const validateEmailChange = React.useCallback(debounce((email) => {
    const isValid = validateEmail(email, true);
    setEmailValidationWarning(!isValid)
  }, EMAIL_DEBOUNCE), [])

  const onEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    validateEmailChange(value);
    if (!value) {
      setEmailValidationWarning(false);
    }
  }

  const onNameChange = (e) => {
    setName(e.target.value);
    setNameValidationWarning(false);
  };

  const onSubmit = async () => {
    const nameValue = name.trim();
    if (!nameValue && !email) {
      setNameValidationWarning(true);
      setEmailValidationWarning(true);
      return;
    } else if (!nameValue) {
      setNameValidationWarning(true);
      return;
    } else if (!email) {
      setEmailValidationWarning(true);
      return;
    }

    try {
      setIsSubmitting(true);
      await submitForm({ name, email }, FORM_TYPE.SUBSCRIBE);
      setEmail('');
      setName('');
      setStatusMessage(SUCCESS_KEY);
    } catch(e) {
      setStatusMessage(ERROR_KEY);
    } finally {
      setIsSubmitting(false);
    }
  }

  const onDrawToggle = () => setIsOpen(!isOpen);
  const onModalToggle = () => {
    setIsModalOpen(!isModalOpen);
    setStatusMessage('');

    if (!isModalOpen) {
      trackGAEvent('overview.subscrible_modal.open', {});
    }
  };

  const renderLogo = () => (
    <Link to={"/"}>
      <StaticImage
        src="../../../images/rebolt-beta.png"
        width={112}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="Rebolt Beta Logo"
        placeholder="blurred"
      />
    </Link>
  );

  const renderDesktopNav = () => (
    <Hidden smDown>
      {renderLogo()}
      <Box display='flex'>
        {PAGES.map((item, index) => <NavigationItem key={index} item={item} />)}
        <Button className={classes.button} variant="contained" onClick={onModalToggle}>
          <Typography className={classes.buttonText}>
            stay in the loop
          </Typography>
        </Button>
      </Box>
    </Hidden>
  );

  const renderMobileNav = () => (
    <Hidden mdUp>
      <IconButton
        className={classes.menuIcon}
        aria-label="open drawer"
        onClick={onDrawToggle}
      >
        <MenuIcon />
      </IconButton>
      {renderLogo()}
      <Button
        className={classes.subscribeMobile}
        onClick={onModalToggle}
      >
        subscribe
      </Button>
      <Drawer PaperProps={{
        className: classes.drawer
      }} anchor='left' open={isOpen} onClose={onDrawToggle}>
        {PAGES.map((item, index) =>(
          <Box key={index}>
            <NavigationItem item={item} paddingY={9} />
            <Divider className={classes.divider} />
          </Box>
        ))}
      </Drawer>
    </Hidden>
  );

  const renderNavItems = () => (
    <>
      {renderDesktopNav()}
      {renderMobileNav()}
      <Modal
        title='stay in the loop'
        subTitle='Once a week, we’ll update you on new companies added to Rebolt + diversity news.'
        isOpen={isModalOpen}
        onClose={onModalToggle}
        maxWidth={330}
        maxHeight={295}
      >
        <Box flexDirection="column" display="flex" justifyContent="flex-end">
          <form className={classes.formRoot} noValidate autoComplete="off">
            <TextField
              label="first name"
              variant="outlined"
              error={nameValidationWarning}
              value={name}
              onChange={onNameChange}
            />
            <TextField
              label="email"
              variant="outlined"
              type='email'
              error={emailValidationWarning}
              autoComplete='email'
              value={email}
              onChange={onEmailChange}
            />
          </form>
          {statusMessage &&
            <Typography className={classes.statusText}>
            {statusMessage}
            </Typography>
          }
          <Button
            className={classes.formButton}
            variant="contained"
            disabled={isSubmitting}
            onClick={onSubmit}
          >
            <Typography style={{ fontWeight: 600 }}>
              subscribe
            </Typography>
          </Button>
        </Box>
      </Modal>
    </>
  );

  return (
      <AppBar position='relative'>
        <Box
          className={classes.root}
          display='flex'
          alignItems='center'
        >
          {renderNavItems()}
        </Box>
      </AppBar>
)};
