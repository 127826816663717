import * as React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import {
  Box,
  SvgIcon
} from '@material-ui/core';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';

import { TikTokIcon } from './TikTokIcon';
import { SectionTitle } from './SectionTitle';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconContainer: {
      backgroundColor: theme.palette.text.primary,
      margin: '0 5px 0 0',
      width: 35,
      borderRadius: 5,
      padding: 3
    },
    icon: {
      color: theme.palette.text.secondary
    }
  }),
);

const PAGES = [
  {
    name: 'Instagram',
    path: 'https://instagram.com/Rebolt',
    component: InstagramIcon
  },
  {
    name: 'Twitter',
    path: 'https://twitter.com/rebolt',
    component: TwitterIcon
  },
  {
    name: 'TikTok',
    path: 'https://vm.tiktok.com/ZM8YcV391/',
    component: TikTokIcon
  },
]

export const Social = () => {
  const classes = useStyles();

  return (
    <>
      <SectionTitle label='follow us' />
      <Box display='flex'>
        {PAGES.map((item, index) => (
          <OutboundLink key={index} href={item.path} target='_blank'>
            <Box
              className={classes.iconContainer}
              display='flex'
              justifyContent='center'
            >
              <SvgIcon
                className={classes.icon}
                titleAccess={item.name}
                component={item.component}
              />
            </Box>
          </OutboundLink>
        ))}
      </Box>
    </>
  );
}
