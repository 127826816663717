import * as React from 'react';

export const debounce = (func, wait) => {
  let timeout;

  return (...args) => {
    const later = () => {
      timeout = null;
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export const useDebounce = (func: () => void, deps: any[], wait: number) => {
  React.useEffect(() => {
    const timer = setTimeout(() => {
      func()
    }, wait);

    return () => clearTimeout(timer);
  }, [...deps, func, wait]);
};
