import * as React from 'react';
import { SvgIcon } from '@material-ui/core';

export const TikTokIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 -4 24 38">
      <path d="M12.5 0h6c0 3.015 4.413 6 6 6v6c-1.815 0-3.996-.798-6-2.145V21c0 4.962-4.038 9-9 9s-9-4.038-9-9 4.038-9 9-9v6a3.003 3.003 0 0 0 0 6c1.656 0 3-1.347 3-3V0Z" fillRule="nonzero"/>
    </SvgIcon>
  );
}
