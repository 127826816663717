import * as React from 'react';
import clsx from 'clsx';
import { Link } from 'gatsby';
import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navElement: {
      margin: '0 20px',
      color: theme.palette.text.secondary,
      textTransform: 'lowercase',
      textAlign: 'center',
      fontWeight: 600,
      fontSize: theme.typography.pxToRem(16),
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    active: {
      color: theme.palette.primary.main
    }
  })
);

export interface NavItem {
  name: string;
  path: string;
};

interface NavigationItemProps {
  paddingY?: number;
  item: NavItem
};

export const NavigationItem = ({ item, paddingY }: NavigationItemProps) => {
  const { name, path } = item;
  const classes = useStyles();
  const isLocationMatch = location.pathname === path;

  return (
    <Box p={1}>
      <Link to={path}>
        <Typography className={clsx(classes.navElement, {
          [classes.active]: isLocationMatch
        })} style={{
          paddingTop: paddingY,
          paddingBottom: paddingY
        }}>
          {name}
        </Typography>
      </Link>
    </Box>
)};
