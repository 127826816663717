import * as React from 'react';
import { Box, SvgIcon, Typography } from '@material-ui/core';

import MailIcon from '@material-ui/icons/Mail';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { SectionTitle } from './SectionTitle';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      color: theme.palette.text.primary
    },
    link: {
      color: theme.palette.text.primary,
      fontSize: theme.typography.pxToRem(18),
    }
  })
);

const REBOLT_EMAIL = 'hi@rebolt.co';
export const ContactUs = () => {
  const classes = useStyles();

  return (
    <>
      <SectionTitle label='contact' />
      <a href={`mailto:${REBOLT_EMAIL}`}>
        <Box display='flex'>
          <SvgIcon className={classes.icon} component={MailIcon} />
          {'\u00A0'}
          <Typography className={classes.link}>{REBOLT_EMAIL}</Typography>
        </Box>
      </a>
    </>
  )
}
