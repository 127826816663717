import * as React from 'react';

import { NavBar } from '../navbar';
import { Footer } from '../footer';

interface LayoutProps {
  children: React.ReactNode;
}

export const Layout = ({ children }: LayoutProps) => (
  <>
    <NavBar />
    {children}
    <Footer />
  </>
);
