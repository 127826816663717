import { makeRequest } from './request';
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape

export enum FORM_TYPE {
  SUBSCRIBE = 'subscribe',
  NEW_COMPANY = 'new_company',
  ADD_COMPANY = 'add_company',
  CONTACT = 'contact'
}

export const validateEmail = (email: string, emptyDefault: boolean = false) => {
  if (!email) {
    return emptyDefault;
  }
  return EMAIL_REGEX.test(email.toLowerCase());
}

export const submitForm = async (payload: Record<string, any>, type: FORM_TYPE) => {
  return await makeRequest('/.netlify/functions/forms', 'POST', { data: {...payload, type }});
}
