import * as React from 'react';
import { Link } from 'gatsby';
import { Typography } from '@material-ui/core';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { SectionTitle } from './SectionTitle';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navElement: {
      lineHeight: 1,
      padding: '0 0 15px 0',
      textTransform: 'lowercase',
      color: theme.palette.text.primary,
      fontSize: theme.typography.pxToRem(18),
    }
  }),
);

const PAGES = [
  {
    name: 'About Us',
    path: '/about'
  },
  {
    name: 'Privacy Policy',
    path: '/privacy'
  },
]

export const NavFooter = () => {
  const classes = useStyles();

  return (
    <>
      <SectionTitle label='about' />
      {PAGES.map(({ path, name }, index) => (
        <Link key={index} to={path}>
          <Typography className={classes.navElement}>
            {name}
          </Typography>
        </Link>
      ))}
    </>
  );
}
