import * as React from 'react';
import { StaticImage } from "gatsby-plugin-image"
import { Box, Grid, Hidden } from '@material-ui/core';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import { NavFooter } from './NavFooter';
import { Social } from './Social';
import { ContactUs } from './ContactUs';
import { Subscribe } from './Subscribe';
import { CopyRight } from './CopyRight';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: '#FEE28C',
      padding: '50px 30px'
    },
    container: {
      maxWidth: 1000,
      width: '100%'
    }
  }),
);

export const Footer = () => {
  const classes = useStyles();
  const renderLogo = () => (
    <StaticImage
      src="../../../images/rebolt-logo.png"
      width={157}
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="Rebolt Logo"
      placeholder="blurred"
    />
  );

  const center = (children: React.ReactNode) => (
    <Box display='flex' alignItems='center' flexDirection='column'>
      {children}
    </Box>
  )

  const renderStandardView = () => (
    <Hidden smDown>
      <Box display='flex' justifyContent='center'>
        <Box className={classes.container}>
          <Grid container spacing={3} >
            <Grid item xs={4}>{renderLogo()}</Grid>
            <Grid item xs={3}><NavFooter /></Grid>
            <Grid item xs={5}><Subscribe /></Grid>
            <Grid item xs={4} />
            <Grid item xs={3}><Social /></Grid>
            <Grid item xs={2}><ContactUs /></Grid>
            <Grid item xs={3}><CopyRight/></Grid>
          </Grid>
        </Box>
      </Box>
    </Hidden>
  )

  const renderMobileView = () => (
    <Hidden mdUp>
      <Box display='flex' justifyContent='center'>
        <Box className={classes.container}>
          <Grid container spacing={4} >
            <Grid item xs={12}>{center(<NavFooter />)}</Grid>
            <Grid item xs={12}>{center(<Subscribe />)}</Grid>
            <Grid item xs={12}>{center(<ContactUs />)}</Grid>
            <Grid item xs={12}>{center(<Social />)}</Grid>
            <Grid item xs={12}><CopyRight center/></Grid>
          </Grid>
        </Box>
      </Box>
    </Hidden>
  )

  return (
    <Box className={classes.root} component='footer'>
      {renderStandardView()}
      {renderMobileView()}
    </Box>
  );
}
