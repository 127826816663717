import * as React from 'react';
import {
  Box,
  Typography,
  Modal as MUModal,
  Backdrop,
  Fade,
  IconButton
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      borderRadius: 5,
      width: '100%',
      height: '100%',
      overflow: 'auto'
    },
    titleContainer: {
      borderBottom: '1px solid #dfe1e4',
      padding: '10px 10px 10px 25px'
    },
    title: {
      fontWeight: 600,
      fontSize: theme.typography.pxToRem(20)
    },
    subTitle: {
      paddingTop: 10,
      fontSize: theme.typography.pxToRem(12)
    },
    children: {
      padding: '10px 25px'
    }
  })
);

interface ModalProps {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  title: string;
  subTitle?: string;
  maxWidth?: number | string;
  minWidth?: number | string;
  maxHeight?: number | string;
  minHeight?: number | string;
}

export const Modal = ({
  title,
  subTitle,
  children,
  isOpen,
  onClose,
  maxWidth = '50%',
  maxHeight = 300,
  minWidth,
  minHeight,
}: ModalProps) => {
  const classes = useStyles();
  return (
    <MUModal
      className={classes.modal}
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
     <Fade in={isOpen}>
       <Box className={classes.paper} style={{ maxWidth, maxHeight, minWidth, minHeight}}>
         <Box
           className={classes.titleContainer}
           display="flex"
           justifyContent="space-between"
           alignItems='center'
          >
          <Box display="flex" flexDirection='column'>
            <Typography className={classes.title}>{title}</Typography>
            {subTitle && <Typography className={classes.subTitle}>{subTitle}</Typography>}
          </Box>
          <IconButton component="span" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box className={classes.children}>
          {children}
        </Box>
       </Box>
     </Fade>
   </MUModal>
  )
}
