import axios from 'axios';
import * as Sentry from "@sentry/gatsby";

const API_BASE = 'https://rebolt.co';
const DEFAULT_HEADERS = {
  'Content-Type': 'application/json'
};

export async function makeRequest(path = '', method = 'GET', body = {}) {
  try {
    return await axios({ method, headers: DEFAULT_HEADERS, ...body, url: `${API_BASE}${path}`});
  } catch(e) {
    Sentry.captureException(e);
    throw e;
  }
}
