import * as React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useStaticQuery, graphql } from 'gatsby';
import { SectionTitle } from './SectionTitle';

interface CopyRightProps {
  center?: boolean;
};

export const CopyRight = ({ center }: CopyRightProps) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const title = (data.site.siteMetadata.title || '').toLowerCase();
  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems={center ? 'center' : 'flex-end'}>
        {!center ? <SectionTitle label={title} isHidden /> : null}
        <Typography
          style={{ fontSize: 18 }}>
            © {title} {new Date().getFullYear()}
        </Typography>
    </Box>
  );
}
