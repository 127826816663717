import * as React from 'react';
import clsx from 'clsx';
import { Typography } from '@material-ui/core';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontWeight: 600,
      textTransform: 'lowercase',
      padding: '0 0 14px 0',
      fontSize: theme.typography.pxToRem(18),
    },
    hidden: {
      opacity: 0
    }
  })
);

interface SectionTitleProps {
  label: string;
  isHidden?: boolean;
}

export const SectionTitle = ({ label, isHidden }: SectionTitleProps) => {
  const classes = useStyles();

  return (
    <Typography className={clsx(classes.title, {[classes.hidden]: isHidden})}>
      {label}
    </Typography>
  );
}
