import * as React from 'react';
import { Box, Button, TextField, Typography } from '@material-ui/core';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { SectionTitle } from './SectionTitle';
import { debounce } from '../../../utils/debounce';
import { submitForm, validateEmail, FORM_TYPE } from '../../../utils/forms';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      marginTop: -15,
    },
    text: {
      marginRight: theme.spacing(2),
    },
    statusText: {
      marginTop: 5,
      fontSize: theme.typography.pxToRem(13)
    },
    button: {
      backgroundColor: theme.palette.text.primary,
      color: theme.palette.text.secondary,
      textTransform: 'lowercase',
      alignSelf: 'flex-end',
      marginTop: 10,
      '&:hover': {
        backgroundColor: theme.palette.text.primary,
        opacity: 0.95
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: theme.palette.text.primary,
      },
      '&:focus': {
        boxShadow: 'none',
      },
    },
  })
);


const EMAIL_DEBOUNCE = 350;
const ERROR_KEY = 'unable to complete request!';
const SUCCESS_KEY = 'thank you for subscribing!';

export const Subscribe = () => {
  const classes = useStyles();
  const [email, setEmail] = React.useState('');
  const [name, setName] = React.useState('');
  const [statusMessage, setStatusMessage] = React.useState('');
  const [emailValidationWarning, setEmailValidationWarning] = React.useState(false);
  const [nameValidationWarning, setNameValidationWarning] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const validateEmailChange = React.useCallback(debounce((email) => {
    const isValid = validateEmail(email, true);
    setEmailValidationWarning(!isValid)
  }, EMAIL_DEBOUNCE), [])

  const onEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    validateEmailChange(value);
    if (!value) {
      setEmailValidationWarning(false);
    }
  }

  const onNameChange = (e) => {
    setName(e.target.value);
    setNameValidationWarning(false);
  };

  const onSubmit = async () => {
    const nameValue = name.trim();
    if (!nameValue && !email) {
      setNameValidationWarning(true);
      setEmailValidationWarning(true);
      return;
    } else if (!nameValue) {
      setNameValidationWarning(true);
      return;
    } else if (!email) {
      setEmailValidationWarning(true);
      return;
    }

    try {
      setIsSubmitting(true);
      await submitForm({ name, email }, FORM_TYPE.SUBSCRIBE);
      setEmail('');
      setName('');
      setStatusMessage(SUCCESS_KEY);
    } catch(e) {
      setStatusMessage(ERROR_KEY);
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <>
      <SectionTitle label='subscribe' />
      <Box display='flex' flexDirection='column'>
        <form className={classes.root} noValidate autoComplete="off">
          <TextField
            className={classes.text}
            error={nameValidationWarning}
            label='first name'
            value={name}
            onChange={onNameChange}
          />
          <TextField
            fullWidth
            error={emailValidationWarning}
            label='email'
            type='email'
            autoComplete='email'
            value={email}
            onChange={onEmailChange}
          />
        </form>
        {statusMessage &&
          <Typography className={classes.statusText}>
          {statusMessage}
          </Typography>
        }
        <Button
          className={classes.button}
          variant="contained"
          disabled={isSubmitting}
          onClick={onSubmit}
        >
          <Typography style={{ fontWeight: 600 }}>
            keep me posted
          </Typography>
        </Button>
      </Box>
    </>
  )
}
